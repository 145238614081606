
import { React } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import '../../main.min.css'
import './CTAButton.min.css'
import './MCTAButton.min.css'
import { lP, oP } from '../../main.js'


export default function CTAButton(props) {
    let darkclass = (props.darkcta != true) ? '' : 'darkcta'
    return (
        // <div className='mainCon'>
        <a href="#" onClick={() => props.onclick()}>
            {/* <div className={'ctabutton noselect ' + props.className + ' ' + darkclass} onClick={() => props.onclick()}> */}
            <div className={'ctabutton noselect ' + props.className + ' ' + darkclass} >
                <font>{props.title}</font>
                {props.hideArrow != true ? <FontAwesomeIcon id="ctaarrow" icon={faArrowRight} /> : ""}
            </div>
        </a>
        // </div>
    )
}