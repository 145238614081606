import { React } from 'react'
import { Helmet } from 'react-helmet'
import { HashLink as Link } from 'react-router-hash-link';

import MainHeader from '../../components/MainHeader/MainHeader.js'
import MainFooter from '../../components/MainFooter/MainFooter.js'
import CTAButton from '../../components/CTAButton/CTAButton.js'

import NewsCard from '../../components/NewsCard/NewsCard'

import newsdatajson from '../../assets/newsdata.json'

import { lP, oP, sT } from '../../main.js'

import '../../main.min.css'
import './Aktuelles.min.css'
import './MAktuelles.min.css'

import icon_news from '../../assets/pictures/icons/news.png'
import icon_personadd from '../../assets/pictures/icons/personadd.png'

import aktuelles_start_img from '../../assets/pictures/backgrounds/thought-catalog-YSZS_nDU8js-unsplash.jpg'

export default function Aktuelles() {
    let newsdatalist = newsdatajson.slice(0, 40)


    return (
        <div className='mainCon' id='mainCon'>
            <MainHeader selectedsite={"aktuelles"} />
            <Helmet>
                <meta charSet="utf-8" />
                <title>Aktuelles & Jobs</title>
                <meta property="og:title" content="Aktuelle Neuigkeiten und Jobs" />
                <meta property="og:description" content="Welche Themen sind gerade relevant bei uns und in der IT-Welt? Welche Jobs bieten wir gerade an? Erfahren Sie hier mehr dazu." />
            </Helmet>
            <section className='sec_start' id='sec_start--aktuelles'>
                <img src={aktuelles_start_img} className='sec_start_bgimg' id='aktuelles_start_img' alt="Hintergrundbild der Seite auf der die Neuigkeiten aufgelistet sind mit einem verschwommenen Smartphone abgebildet" />
                <h1 className="sec_start_title noselect">Aktuelle Neuigkeiten<br />von uns</h1>
                <Link smooth to='#sec_next--aktuelles--aktuelles' >
                    <CTAButton className="sec_start_btn" hideArrow={true} title={"Erfahren Sie mehr"} darkcta={false} onclick={() => { }}></CTAButton>
                </Link>
                {/* <CTAButton className="sec_start_btn" hideArrow={true} title={"Erfahren Sie mehr"} darkcta={false} onclick={() => sT('#sec_next--aktuelles--aktuelles')}></CTAButton> */}
            </section>

            <section className='sec_next' id='sec_next--aktuelles--aktuelles'>
                <div className='sec_next_cont' id="sec_next--aktuelles--aktuelles--cont">

                    <h2>Aktuelles & Jobs</h2>
                    {newsdatalist.map(function (newsdata) {
                        return (
                            <NewsCard newsid={newsdata.newsid} title={newsdata.title} datum={newsdata.datum} iconimg={(newsdata.icontype == "personadd") ? icon_personadd : icon_news} ausschnitt={newsdata.ausschnitt} />
                        )
                    })}
                </div>
                <br /><br />
                <br /><br />
                <CTAButton className="cta_bottom cta_outbottom--aktuelles" id='ctabottom--aktuelles--contact' hideArrow={false} title={"Jetzt Kontakt aufnehmen"} darkcta={true} onclick={() => lP('/kontakt')}></CTAButton>

            </section>

            <MainFooter />
        </div>
    )
}