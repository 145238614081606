import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom'
import { React, useEffect, useState } from 'react'

import './App.min.css'
import './MApp.min.css'


import Cookies from 'universal-cookie'

import Home from './pages/Home/Home'
import Page404 from './pages/Page404/Page404'


import { generateUUID, getBrowser } from './main.js'

import Kontakt from './pages/Kontakt/Kontakt.js'
import Services from './pages/Services/Services.js'
import About from './pages/About/About.js'
import Referenzen from './pages/Referenzen/Referenzen.js'
import Aktuelles from './pages/Aktuelles/Aktuelles.js'
import AktuellesDetail from './pages/AktuellesDetail/AktuellesDetail.js'
import Impressum from './pages/Impressum/Impressum.js'
import Datenschutz from './pages/Datenschutz/Datenschutz.js'

const cookies = new Cookies()

export default function App() {
    useEffect(() => {
        try {
            // window.screen.orientation.lock("portrait")
        } catch (e) {
            console.log(e)
        }
    }, [])

    let cur_userid = cookies.get('userid')
    let loggedin = (cur_userid != "" && cur_userid != undefined)

    var agentinfo = getBrowser(navigator.userAgent)
    var location = window.location

    // console.log(agentinfo.browser)
    // console.log(agentinfo.agent)
    // console.log(location.pathname)



    return (
        <Router>
            {/* basename={'/'} */}
            <Switch>

                <Route path="/about" component={About} />
                <Route path="/kontakt" component={Kontakt} />
                <Route path="/services" component={Services} />
                <Route path="/referenzen" component={Referenzen} />

                <Route path="/impressum" component={Impressum} />
                <Route path="/datenschutz" component={Datenschutz} />

                <Route path="/aktuelles/:newsidurl" component={AktuellesDetail} />
                <Route path="/aktuelles" exact={true} component={Aktuelles} />
                <Route path="/jobs*" >
                    <Redirect to="/aktuelles" />
                </Route>

                {/* Alte URLs */}
                <Route path="/aktuelle-infos*" >
                    <Redirect to="/aktuelles" />
                </Route>
                <Route path="/team*" >
                    <Redirect to="/about" />
                </Route>
                <Route path="/leistungen/*" >
                    <Redirect to="/services" />
                </Route>
                <Route path="/referenzen/de/" >
                    <Redirect to="/referenzen" />
                </Route>
                <Route path="/partner/de/" >
                    <Redirect to="/referenzen" />
                </Route>
                <Route path="/kontakt-anfahrt/*" >
                    <Redirect to="/kontakt" />
                </Route>

                <Route path="/" component={Home} exact />
                <Route path="/*" component={Page404} />
                <Route component={Page404} />
            </Switch>
        </Router>
    )
}

