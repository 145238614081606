
import { React } from 'react'

import '../../main.min.css'
import './NewsCard.min.css'
import './MNewsCard.min.css'
import { lP, oP } from '../../main.js'


export default function NewsCard(props) {
    return (
        <div className={'newscard noselect ' + props.className} onClick={() => lP('/aktuelles/' + props.newsid)}>
            <img className='newscardimg' src={props.iconimg} alt="Icon der Newskategorie" />
            <div className='newscardtextcont'>
                <b>{props.title}</b>
                <p>{props.datum} ● {props.ausschnitt}</p>
            </div>

        </div>
    )
}