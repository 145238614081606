import { React } from 'react'
import { Helmet } from 'react-helmet'
import { HashLink as Link } from 'react-router-hash-link';

import MainHeader from '../../components/MainHeader/MainHeader.js'
import MainFooter from '../../components/MainFooter/MainFooter.js'
import CTAButton from '../../components/CTAButton/CTAButton.js'

import { lP, oP, sT } from '../../main.js'

import '../../main.min.css'
import './Kontakt.min.css'
import './MKontakt.min.css'

import kontakt_start_img from '../../assets/pictures/backgrounds/julian-hochgesang-Dkn8-zPIbwo-unsplash.jpg'

import liepert_map from '../../assets/pictures/liepert-map.png'
import teamviewer_dl from '../../assets/pictures/icons/teamviewer-logo-icon-9.png'


export default function Kontakt() {


    return (
        <div className='mainCon' id='mainCon'>
            <MainHeader selectedsite={"kontakt"} />
            <Helmet>
                <meta charSet="utf-8" />
                <title>Kontakt</title>
                <meta property="og:title" content="Kontakt" />
                <meta property="og:description" content="Haben Sie Fragen zu unseren Leistungen? Benötigen Sie Hilfe bei Ihrem IT-Projekt? Wollen Sie mehr über uns erfahren? Kontaktieren Sie uns jetzt ganz unverbindlich!" />
            </Helmet>
            <section className='sec_start' id='sec_start--kontakt'>
                <img src={kontakt_start_img} className='sec_start_bgimg' id='kontakt_start_img' alt="Hintergrundbild der Kontaktseite mit einem verschwommenen Telefon im Hintergrund" />
                <h1 className="sec_start_title noselect">So erreichen Sie uns:</h1>

                <Link smooth to='#sec_next--kontakt--kontakt' >
                    <CTAButton className="sec_start_btn" hideArrow={true} title={"Jetzt kontaktieren"} darkcta={false} onclick={() => { }}></CTAButton>
                </Link>
                {/* <CTAButton className="sec_start_btn" hideArrow={true} title={"Jetzt kontaktieren"} darkcta={false} onclick={() => sT('#sec_next--kontakt--kontakt')}></CTAButton> */}
            </section>

            <section className='sec_next' id='sec_next--kontakt--kontakt'>
                <div className='sec_next_cont' id="sec_next--kontakt--kontakt--cont">

                    <h2>Kontakt</h2>

                    <div className='contactcont'>
                        <div className='contacttextcont'>
                            <b>it.liepert.gmbh</b>
                            <p>
                                it-systemhaus<br />
                                ingenieurbüro
                            </p>
                            <a href="https://www.google.com/maps/search/?api=1&query=itliepert+Zeissweg+4+87700+Memmingen&hl=de" className="addresslink">Zeissweg 4<br />87700 Memmingen</a>
                            <br />
                            <a href="tel:0833196700">fon +49.8331.9670.0</a>
                            <a href="fax:08331967040">fax +49.8331.9670.40</a>
                            <a href="mailto:info@liepert.de">eMail info@liepert.de</a>
                        </div>
                        <img src={liepert_map} alt={"Karte der Umgebung für die Anfahrt"} />

                    </div>
                    <br /><br />
                    <div className='teamviewer_cont' onClick={() => oP('https://get.teamviewer.com/6je77gs')}>
                        <img src={teamviewer_dl} className='teamviewer_img'></img>
                        <CTAButton className="cta_more" hideArrow={true} title={"TeamViewer herunterladen"} darkcta={true} onclick={() => oP('https://get.teamviewer.com/6je77gs')}></CTAButton>
                    </div>
                </div>
                <br /><br />
                <br /><br />
                <br /><br />

            </section>

            <MainFooter />
        </div>
    )
}