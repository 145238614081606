import { React } from 'react'
import { Helmet } from 'react-helmet'
import { HashLink as Link } from 'react-router-hash-link';

import MainHeader from '../../components/MainHeader/MainHeader.js'
import MainFooter from '../../components/MainFooter/MainFooter.js'
import CTAButton from '../../components/CTAButton/CTAButton.js'

import PartnerCard from '../../components/PartnerCard/PartnerCard.js'


import { lP, oP, sT } from '../../main.js'

import '../../main.min.css'
import './Referenzen.min.css'
import './MReferenzen.min.css'


import partner_balzer from '../../assets/pictures/partner/balzer.svg'
import partner_beck from '../../assets/pictures/partner/beck.jpg'
import partner_baypack from '../../assets/pictures/partner/baypack.png'
import partner_ematec from '../../assets/pictures/partner/ematec.jpg'
import partner_genesis from '../../assets/pictures/partner/genesis.svg'
import partner_gerstberger from '../../assets/pictures/partner/gerstberger.png'
import partner_kbh from '../../assets/pictures/partner/kbh.jpg'
import partner_mammut from '../../assets/pictures/partner/mammut.svg'
import partner_mendes from '../../assets/pictures/partner/mendes.jpg'
import partner_wassermann from '../../assets/pictures/partner/wassermann.jpg'
import partner_pfeifermarketing from '../../assets/pictures/partner/pfeifermarketing.png'
import partner_reisacher from '../../assets/pictures/partner/reisacher.png'
import partner_zettler from '../../assets/pictures/partner/zettler.jpg'
import partner_thwmm from '../../assets/pictures/partner/thwmm.png'

import partner_hp from '../../assets/pictures/partner/hp.png'
import partner_tobit from '../../assets/pictures/partner/tobit.jpeg'
import partner_microsoft from '../../assets/pictures/partner/microsoft.png'
import partner_lancom from '../../assets/pictures/partner/lancom.png'
import partner_vmware from '../../assets/pictures/partner/vmware.jpg'
import partner_axis from '../../assets/pictures/partner/axis.jpg'
import partner_agfeo from '../../assets/pictures/partner/agfeo.png'
// import partner_mcafee from '../../assets/pictures/partner/mcafee.png'
import partner_trellix from '../../assets/pictures/partner/trellix.png'
import partner_sophos from '../../assets/pictures/partner/sophos.png'
import partner_lexware from '../../assets/pictures/partner/lexware.png'
import partner_veeam from '../../assets/pictures/partner/veeam.png'
import partner_ton from '../../assets/pictures/logos/ton_logo-breit_black-cutcompressed.png'

import referenzen_start_img from '../../assets/pictures/backgrounds/gabrielle-henderson-HJckKnwCXxQ-unsplash.jpg'

export default function Referenzen() {

    return (
        <div className='mainCon' id='mainCon'>
            <MainHeader selectedsite={"referenzen"} />
            <Helmet>
                <meta charSet="utf-8" />
                <title>Referenzen & Partner</title>
                <meta property="og:title" content="Referenzen & Partner" />
                <meta property="og:description" content="Hier finden Sie unsere Kundenreferenzen und unsere langjährigen Partner" />
            </Helmet>

            <section className='sec_start' id='sec_start--referenzen'>
                <img src={referenzen_start_img} className='sec_start_bgimg' id='referenzen_start_img' alt="Hintergrundbild für die Seite der Referenzen und Partner" />
                <h1 className="sec_start_title noselect">Das sind unsere Referenzen und Partner:</h1>
                <Link smooth to='#sec_next--referenzen--referenzen' >
                    <CTAButton className="sec_start_btn" hideArrow={true} title={"Erfahren Sie mehr"} darkcta={false} onclick={() => { }}></CTAButton>
                </Link>
                {/* <CTAButton className="sec_start_btn" hideArrow={true} title={"Erfahren Sie mehr"} darkcta={false} onclick={() => sT('#sec_next--referenzen--referenzen')}></CTAButton> */}
            </section>

            <section className='sec_next' id='sec_next--referenzen--referenzen'>
                <div className='sec_next_cont' id="sec_next--referenzen--kunden--cont">
                    <h2>Unsere Kunden</h2>
                    <div className='kundengrid'>
                        <img className='kundengridimg' alt="Logo des Kundens" src={partner_balzer} onClick={() => oP('https://www.balzer-mm.de/')} />
                        <img className='kundengridimg' alt="Logo des Kundens" src={partner_beck} onClick={() => oP('https://www.bauunternehmung-beck.de/')} />
                        <img className='kundengridimg' alt="Logo des Kundens" src={partner_baypack} onClick={() => oP('https://www.baypack.eu/')} />
                        <img className='kundengridimg' alt="Logo des Kundens" src={partner_ematec} onClick={() => oP('https://www.ematec.de/')} />
                        <img className='kundengridimg' alt="Logo des Kundens" src={partner_genesis} onClick={() => oP('https://www.genesis-europe.de/')} />
                        <img className='kundengridimg' alt="Logo des Kundens" src={partner_gerstberger} onClick={() => oP('https://gerstberger.com/')} />
                        <img className='kundengridimg' alt="Logo des Kundens" src={partner_kbh} onClick={() => oP('https://www.k-b-h.de/')} />
                        <img className='kundengridimg' alt="Logo des Kundens" src={partner_mammut} onClick={() => oP('https://www.mammut.ch/')} />
                        <img className='kundengridimg' alt="Logo des Kundens" src={partner_wassermann} onClick={() => oP('https://www.moebel-wassermann.de/')} />
                        <img className='kundengridimg' alt="Logo des Kundens" src={partner_mendes} onClick={() => oP('https://www.mendes.de/')} />
                        <img className='kundengridimg' alt="Logo des Kundens" src={partner_pfeifermarketing} onClick={() => oP('https://www.pfeifer-marketing.de/')} />
                        <img className='kundengridimg' alt="Logo des Kundens" src={partner_reisacher} onClick={() => oP('https://www.reisacher.de/')} />
                        <img className='kundengridimg' alt="Logo des Kundens" src={partner_zettler} onClick={() => oP('https://www.zettler-bau.de/')} />
                        <img className='kundengridimg' alt="Logo des Kundens" src={partner_thwmm} onClick={() => oP('https://www.thw-memmingen.de/')} />

                    </div>
                </div>

                <div className='sec_next_cont' id="sec_next--referenzen--partner--cont">
                    <h2>Unsere Partner</h2>
                    <div className='partnerlist'>
                        <PartnerCard title='Hewlett-Packard Silber Partner' logoimg={partner_hp} onclick={() => oP('https://www.hewlett-packard.de/')} description="Auf Grund unserer nachgewiesenen Qualifikationen und Zertifikate sind wir seit 2009 Partner von Hewlett Packard Enterprise und HP Inc." />
                        <PartnerCard title='Tobit Autorisierter Reseller' logoimg={partner_tobit} onclick={() => oP('https://www.tobit.com/')} description="Seit 1993 installieren wir die Kommunikationsprodukte aus dem Hause Tobit. Wir gehören zu den Top-20-Partnern in Deutschland." />
                        <PartnerCard title='Microsoft Partner Network' logoimg={partner_microsoft} onclick={() => oP('https://www.microsoft.de/')} description="Mit 2 'Microsoft Certified Professionals' und Mitglied in der 'Small Bussiness Specialist Community' verfügen wir über langjährige Kompetenz bei Microsoft Produkten." />
                        <PartnerCard title='LANCOM Advanced Partner Silber' logoimg={partner_lancom} onclick={() => oP('https://www.lancom-systems.de/')} description="LANCOM Systems ist führender deutscher Hersteller zuverlässiger Kommunikationslösungen für große, mittelständische und kleine Unternehmen, Behörden und Institutionen." />
                        <PartnerCard title='VMware Professional Solution Provider' logoimg={partner_vmware} onclick={() => oP('https://www.vmware.com/de/')} description="Marktführer bei Virtualisierungslösungen.	" />
                        <PartnerCard title='Axis Fachhändler' logoimg={partner_axis} onclick={() => oP('https://www.axis.com/de-de')} description="Die perfekte zentrale Kameraüberwachung für Ihre Immobilien." />
                        <PartnerCard title='AGFEO Fachhändler' logoimg={partner_agfeo} onclick={() => oP('https://www.agfeo.de/')} description="Telefonanlagen und Telekommunikation mit starker CTI-Lösung. Wir sind 'Bronze' und 'Silber'-zertifizierter Fachhändler." />
                        {/* <PartnerCard title='McAfee Security-Partner' logoimg={partner_mcafee} onclick={() => oP('https://us.mcafee.com/')} description="Wir beraten Sie professionell zu IT-Security-Lösungen." /> */}
                        <PartnerCard title='Trellix Security-Partner' logoimg={partner_trellix} onclick={() => oP('https://www.trellix.com/de-de/')} description="Wir beraten Sie professionell zu IT-Security-Lösungen." />
                        <PartnerCard title='Sophos Authorized-Partner' logoimg={partner_sophos} onclick={() => oP('https://sophos.com/de-DE/')} description="Wir sind authorisierter Partner von Sophos, dem in der Industrie seit Jahren bewährten Cybersecurity-Anbieter." />
                        <PartnerCard title='Anerkannter LEXWARE Partner' logoimg={partner_lexware} onclick={() => oP('https://www.lexware.de/')} description="Wir sind anerkannter Partner der Buchhaltungssoftware Lexware." />
                        <PartnerCard title='VEEAM Silver Reseller' logoimg={partner_veeam} onclick={() => oP('https://www.veeam.com/de/')} description="Lösungen zur Datensicherung und Verfügbarkeit auch in virtuellen Umgebungen." />
                        <PartnerCard title='pfeifer marketing' logoimg={partner_pfeifermarketing} onclick={() => oP('https://www.pfeifer-marketing.de/')} description="Content Management Systeme zur einfachen Verwaltung eines Internetauftrittes." />
                        <PartnerCard title='ToNetwork' logoimg={partner_ton} onclick={() => oP('https://tonetwork.de/')} description="Web- und Appentwicklung sowie Individualsoftware und Services im Bereich Media, Künstliche Intelligenz und Prozessautomatisierung." />
                    </div>
                </div>

                <br /><br />
                <br /><br />

                <CTAButton className="cta_bottom cta_outbottom--referenzen" hideArrow={false} title={"Jetzt Kontakt aufnehmen"} darkcta={true} onclick={() => lP('/kontakt')}></CTAButton>

            </section>




            <MainFooter />
        </div >
    )
}