import { React } from 'react'
import { Helmet } from 'react-helmet'
import { HashLink as Link } from 'react-router-hash-link';

import MainHeader from '../../components/MainHeader/MainHeader.js'
import MainFooter from '../../components/MainFooter/MainFooter.js'
import CTAButton from '../../components/CTAButton/CTAButton.js'

import { lP, oP, sT } from '../../main.js'

import '../../main.min.css'
import './About.min.css'
import './MAbout.min.css'


import icon_gruppe from '../../assets/pictures/icons/gruppe.png'
import icon_tor from '../../assets/pictures/icons/tor.png'

import about_start_img from '../../assets/pictures/backgrounds/it-liepert-autos.jpg'
import about_persona_img from '../../assets/pictures/backgrounds/marten-bjork-6dW3xyQvcYE-unsplash.jpg'

// import teambild_klaus_liepert from '../../assets/pictures/teambilder/liepert_klaus_detail-2.jpg'
// import teambild_ralf_konefal from '../../assets/pictures/teambilder/konefal_ralf_detail-2.jpg'

import teambild_klaus_liepert from '../../assets/pictures/teambilder/liepert_klaus_detail-3.png'
import teambild_ralf_konefal from '../../assets/pictures/teambilder/konefal_ralf_detail-3.png'


export default function About() {


    return (
        <div className='mainCon' id='mainCon'>
            <MainHeader selectedsite={"about"} />
            <Helmet>
                <meta charSet="utf-8" />
                <title>Über uns</title>
                <meta property="og:title" content="Über uns" />
                <meta property="og:description" content="Erfahren Sie mehr über uns und wie wir arbeiten." />
            </Helmet>
            <section className='sec_start' id='sec_start--services'>
                <img src={about_start_img} className='sec_start_bgimg' id='about_start_img' alt="Hintergrundbild der Über-Uns-Seite mit dem Fuhrpark der Firma im Hintergrund" />
                <h1 className="sec_start_title noselect">Wer ist die<br />it.liepert.gmbh?</h1>
                <Link smooth to='#sec_next--about--about' >
                    <CTAButton className="sec_start_btn" hideArrow={true} title={"Lernen Sie uns kennen"} darkcta={false} onclick={() => { }}></CTAButton>
                </Link>
                {/* <CTAButton className="sec_start_btn" hideArrow={true} title={"Lernen Sie uns kennen"} darkcta={false} onclick={() => sT('#sec_next--about--about')}></CTAButton> */}
            </section>

            <section className='sec_next' id='sec_next--about--about'>
                <div className='sec_next_cont' id="sec_next--about--who--cont">
                    <h2>Wer sind wir?</h2>
                    <div className='abouttextcont'>
                        <p>
                            Die it.liepert.gmbh ist ein regional tätiges Systemhaus, das seit 1993 kleine und mittelständische Unternehmen im Großraum Memmingen in allen Fragen rund um die IT und Kommunikation berät und betreut. Dabei legen wir besonderen Wert auf integrierte Lösungen, die die informationstechnischen Prozesse des Unternehmens in seiner Gesamtheit bestmöglich erfasst und abbildet. Viele namhafte Unternehmen der Region zählen seit vielen Jahren zu unserem Kundenstamm.
                        </p>
                        <img className='abouttextcontimg' src={icon_gruppe} />
                    </div>
                </div>
                <div className='sec_next_cont' id="sec_next--about--why--cont">
                    <h2>Wofür stehen wir?</h2>
                    <div className='abouttextcont'>
                        <p>
                            Bei der it.liepert.gmbh stehen wir für maßgeschneiderte, zukunftsorientierte IT-Lösungen, die unsere Kunden im Herzen des digitalen Wandels positionieren. Unsere Philosophie basiert auf einer tiefen Verpflichtung zu Qualität, Nachhaltigkeit und einer engen, vertrauensvollen Zusammenarbeit mit unseren Kunden. Wir glauben an eine IT, die nicht nur Probleme löst, sondern aktiv zum Wachstum und Erfolg der Unternehmen beiträgt, mit denen wir arbeiten. Unsere Expertise und langjährige Erfahrung ermöglichen es uns, die einzigartigen Herausforderungen und Chancen jedes Unternehmens zu verstehen und Lösungen zu entwickeln, die nicht nur heute, sondern auch in Zukunft Bestand haben.                        </p>
                        <img className='abouttextcontimg' src={icon_tor} />
                    </div>
                </div>
            </section>


            <section className='sec_nextmbg' id='sec_next--about--persona'>
                <img src={about_persona_img} className='sec_nextmbg_bgimg' id='about_persona_img' alt="Hintergrundbild der Über-Uns-Seite mit einer verschwommenen Person mit Aktenkoffer" />
                <div className='sec_nextmbg_onimgcont' id="sec_next--about--persona--cont">
                    {/* <h2>Ihre Ansprechpartner</h2> */}

                    <div className='aboutteamcont'>
                        <div className='aboutteamcard' id="klausliepert">
                            <img src={teambild_klaus_liepert} alt={"Bild von Klaus Liepert (Geschäftsführer)"} />
                            <div className='aboutteamcardtextcont'>
                                <b>Klaus Liepert</b>
                                <p>
                                    Dipl. Informatiker (FH), Geschäftsführer<br />
                                    <a href="mailto:klaus.liepert@liepert.de">klaus.liepert@liepert.de</a><br />
                                    <a href="tel:08331967020">fon 08331.9670.20</a><br />
                                    <a href="tel:01716219933">mobil 0171.621.99.33</a><br />
                                    <br />
                                    seit 2002 IHK-Prüfungsausschuss<br />
                                    seit 2008 Mitglied der Regionalversammlung der IHK<br />
                                    <br />
                                    Datenschutzbeauftragter - TÜV geprüft & Zertifiziert<br />
                                    Tobit Software Certified Professional (TSCP)<br />
                                </p>
                            </div>
                        </div>
                        <div className='aboutteamcard' id="ralfkonefal">
                            <img src={teambild_ralf_konefal} alt={"Bild von Ralf Konefal (Geschäftsführer)"} />
                            <div className='aboutteamcardtextcont'>
                                <b>Ralf Konefal</b>
                                <p>
                                    Geschäftsführer<br />
                                    <a href="mailto:ralf.konefal@liepert.de">ralf.konefal@liepert.de</a><br />
                                    <a href="tel:08331967020">fon 08331.9670.30</a><br />
                                    <a href="tel:01716129808">mobil 0171.612.98.08</a><br />
                                    <br />
                                    Datenschutzbeauftragter - Tüv geprüft & Zertifiziert<br />
                                    Tobit Software Certified Professional (TSCP)<br />
                                    hp ASP Commercial SMB Solutions<br />
                                </p>
                            </div>
                        </div>
                    </div>
                    <br /><br />
                    <br /><br />
                    <br /><br />

                    <CTAButton className="cta_bottom" hideArrow={false} title={"Jetzt Kontakt aufnehmen"} darkcta={true} onclick={() => lP('/kontakt')}></CTAButton>

                </div>
            </section>



            <MainFooter />
        </div>
    )
}