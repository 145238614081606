import { React } from 'react'
import { Helmet } from 'react-helmet'
import MainHeader from '../../components/MainHeader/MainHeader.js'
import MainFooter from '../../components/MainFooter/MainFooter.js'


import { lP, oP, sT } from '../../main.js'

import '../../main.min.css'
import './Datenschutz.min.css'
import './MDatenschutz.min.css'






export default function Datenschutz() {
    var gaProperty = 'UA-6008800-1';
    var disableStr = 'ga-disable-' + gaProperty;
    if (document.cookie.indexOf(disableStr + '=true') > -1) {
        window[disableStr] = true;
    }
    function gaOptout() {
        document.cookie = disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
        window[disableStr] = true;
        alert('Die Erfassung durch Google Analytics wurde für diese Website deaktiviert.' + String.fromCharCode(10) + String.fromCharCode(10) + 'Sie können dies reaktivieren, indem Sie Ihre Browser-Cookies löschen.');
    }

    return (
        <div className='mainCon' id='mainCon'>
            <MainHeader selectedsite={"datenschutz"} />
            <Helmet>
                <meta charSet="utf-8" />
                <title>Datenschutzerklärung</title>
                <meta property="og:title" content="Datenschutzerklärung" />
                <meta property="og:description" content="Hier finden Sie unsere Datenschutzerklärung" />
            </Helmet>
            <section className='sec_next' id='sec_next--datenschutz'>
                <div className='sec_next_cont' id="sec_next--datenschutz--cont">
                    {/* <a className="pagebackbtn" href="/">◀️ Zurück zur Startseite</a> */}
                    <h2>Datenschutz</h2>
                    <p>

                        <h3>Datenschutzerklärung nach Art. 13 und 21 DSGVO</h3>
                        <br />
                        <b>Grundlegendes</b><br />
                        <br />
                        Diese Datenschutzerklärung soll die Nutzer dieser Website über die Art, den Umfang und den Zweck der Erhebung und Verwendung personenbezogener Daten durch den Websitebetreiber it.liepert.gmbh informieren.<br />
                        <br />
                        Der Websitebetreiber nimmt Ihren Datenschutz sehr ernst und behandelt Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Vorschriften. Da durch neue Technologien und die ständige Weiterentwicklung dieser Webseite Änderungen an dieser Datenschutzerklärung vorgenommen werden können, empfehlen wir Ihnen sich die Datenschutzerklärung in regelmäßigen Abständen wieder durchzulesen.<br />
                        <br />
                        Definitionen der verwendeten Begriffe (z.B. “personenbezogene Daten” oder “Verarbeitung”) finden Sie in Art. 4 DSGVO.<br />
                        <br />
                        <br />
                        <b>Name und Anschrift des für die Verarbeitung Verantwortlichen</b><br />
                        <br />
                        Verantwortliche Stelle im Sinne der DSGVO ist die it.liepert.gmbh, Zeissweg 4, 87700 Memmingen<br />
                        <br />
                        <br />
                        <b>Zugriffsdaten</b><br />
                        <br />
                        Wir, der Websitebetreiber bzw. Seitenprovider, erheben aufgrund unseres berechtigten Interesses (s. Art. 6 Abs. 1 lit. f. DSGVO) Daten über Zugriffe auf die Website und speichern diese als „Server-Logfiles“ auf dem Server der Website ab. Folgende Daten werden so protokolliert:<br />
                        <br />
                        <ul>
                            <li>aufgerufene Seiten</li>
                            <li>verwendeter Browser/Betriebssystem/Geräte/Modell</li>
                            <li>Datum u. Uhrzeit des Zugriffs</li>
                            <li>verwendete Suchmaschine oder Link über den man auf die Website gelangt ist</li>
                            <li>anonymisierte IP‐Adresse (nicht zurückverfolgbar)</li>
                            <li>anonyme Sitzungskennung</li>
                            <li>eingestellte Sprache im Browser</li>
                        </ul>
                        <br />
                        Die Server-Logfiles werden für maximal 2 Jahre gespeichert und anschließend gelöscht. Die Speicherung der Daten erfolgt aus Sicherheitsgründen, um z. B. Missbrauchsfälle aufklären zu können. Müssen Daten aus Beweisgründen aufgehoben werden, sind sie solange von der Löschung ausgenommen bis der Vorfall endgültig geklärt ist.<br />
                        <br />
                        <br />
                        <b>Reichweitenmessung &amp; Cookies</b><br />
                        <br />
                        Diese Website verwendet Cookies zur pseudonymisierten Reichweitenmessung, die entweder von unserem Server oder dem Server Dritter an den Browser des Nutzers übertragen werden. Bei Cookies handelt es sich um kleine Dateien, welche auf Ihrem Endgerät gespeichert werden. Ihr Browser greift auf diese Dateien zu. Durch den Einsatz von Cookies erhöht sich die Benutzerfreundlichkeit und Sicherheit dieser Website.<br />
                        <br />
                        Gängige Browser bieten die Einstellungsoption, Cookies nicht zuzulassen. Hinweis: Es ist nicht gewährleistet, dass Sie auf alle Funktionen dieser Website ohne Einschränkungen zugreifen können, wenn Sie entsprechende Einstellungen vornehmen.<br />
                        <br />
                        <b><br />
                            Erfassung und Verarbeitung personenbezogener Daten</b><br />
                        <br />
                        Der Websitebetreiber erhebt, nutzt und gibt Ihre personenbezogenen Daten nur dann weiter, wenn dies im gesetzlichen Rahmen erlaubt ist oder Sie in die Datenerhebung einwilligen.<br />
                        Als personenbezogene Daten gelten sämtliche Informationen, welche dazu dienen, Ihre Person zu bestimmen und welche zu Ihnen zurückverfolgt werden können – also beispielsweise Ihr Name, Ihre E-Mail-Adresse und Telefonnummer.<br />
                        <br />
                        Diese Website können Sie auch besuchen, ohne Angaben zu Ihrer Person zu machen. Zur Verbesserung unseres Online-Angebotes speichern wir jedoch (ohne Personenbezug) Ihre Zugriffsdaten auf diese Website. Zu diesen Zugriffsdaten gehören z. B. die von Ihnen angeforderte Datei oder der Name Ihres Internet-Providers. Durch die Anonymisierung der Daten sind Rückschlüsse auf Ihre Person nicht möglich.<br />
                        <br />
                        <br />
                        <b>Umgang mit Kontaktdaten</b><br />
                        <br />
                        Nehmen Sie mit uns als Websitebetreiber durch die angebotenen Kontaktmöglichkeiten Verbindung auf, werden Ihre Angaben gespeichert, damit auf diese zur Bearbeitung und Beantwortung Ihrer Anfrage zurückgegriffen werden kann. Ohne Ihre Einwilligung werden diese Daten nicht an Dritte weitergegeben.<br />
                        <br />
                        <br />
                        <b>Google Analytics</b><br />
                        <br />
                        Diese Website nutzt aufgrund unserer berechtigten Interessen zur Optimierung und Analyse unseres Online-Angebots im Sinne des Art. 6 Abs. 1 lit. f. DSGVO den Dienst „Google Analytics“, welcher von der Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) angeboten wird. Der Dienst (Google Analytics) verwendet „Cookies“ – Textdateien, welche auf Ihrem Endgerät gespeichert werden. Die durch die Cookies gesammelten Informationen werden im Regelfall an einen Google-Server in den USA gesandt und dort gespeichert.<br />
                        <br />
                        Google LLC hält das europäische Datenschutzrecht ein und ist unter dem Privacy-Shield-Abkommen zertifiziert:<a href="http:// https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"> https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active</a><br />
                        <br />
                        Auf dieser Website greift die IP-Anonymisierung. Die IP-Adresse der Nutzer wird innerhalb der Mitgliedsstaaten der EU und des Europäischen Wirtschaftsraum und in den anderen Vertragsstaaten des Abkommens gekürzt. Nur in Einzelfällen wird die IP-Adresse zunächst ungekürzt in die USA an einen Server von Google übertragen und dort gekürzt. Durch diese Kürzung entfällt der Personenbezug Ihrer IP-Adresse. Die vom Browser übermittelte IP-Adresse des Nutzers wird nicht mit anderen von Google gespeicherten Daten kombiniert.<br />
                        <br />
                        Im Rahmen der Vereinbarung zur Auftragsdatenvereinbarung, welche wir als Websitebetreiber mit der Google Inc. geschlossen haben, erstellt diese mithilfe der gesammelten Informationen eine Auswertung der Websitenutzung und der Websiteaktivität und erbringt mit der Internetnutzung verbundene Dienstleistungen.<br />
                        <br />
                        Die von Google in unserem Auftrag erhobenen Daten werden genutzt, um die Nutzung unseres Online-Angebots durch die einzelnen Nutzer auswerten zu können, z. B. um Reports über die Aktivität auf der Website zu erstellen, um unser Online-Angebot zu verbessern.<br />
                        <br />
                        Sie haben die Möglichkeit, die Speicherung der Cookies auf Ihrem Gerät zu verhindern, indem Sie in Ihrem Browser entsprechende Einstellungen vornehmen. Es ist nicht gewährleistet, dass Sie auf alle Funktionen dieser Website ohne Einschränkungen zugreifen können, wenn Ihr Browser keine Cookies zulässt.<br />
                        <br />
                        Weiterhin können Sie durch ein Browser-Plugin verhindern, dass die durch Cookies gesammelten Informationen (inklusive Ihrer IP-Adresse) an die Google Inc. gesendet und von der Google Inc. genutzt werden. Folgender Link führt Sie zu dem entsprechenden Plugin: <a href="https://tools.google.com/dlpage/gaoptout?hl=de">https://tools.google.com/dlpage/gaoptout?hl=de</a><br />
                        <br />
                        Alternativ verhindern Sie mit einem Klick auf den folgenden Link, dass Google Analytics innerhalb dieser Website Daten über Sie erfasst: <a href="javascript:gaOptout()">Google Analytics deaktivieren</a>. Mit dem Klick auf obigen Link laden Sie ein „Opt-Out-Cookie“ herunter. Ihr Browser muss die Speicherung von Cookies also hierzu grundsätzlich erlauben. Löschen Sie Ihre Cookies regelmäßig, ist ein erneuter Klick auf den Link bei jedem Besuch dieser Website vonnöten.<br />
                        <br />
                        Hier finden Sie weitere Informationen zur Datennutzung durch die Google Inc.:<br />
                        <a href="https://policies.google.com/privacy/partners?hl=de"><br />
                            https://policies.google.com/privacy/partners?hl=de</a> (Daten, die von Google-Partnern erhoben werden)<br />
                        <a href="https://adssettings.google.de/authenticated ">https://adssettings.google.de/authenticated</a> (Einstellungen über Werbung, die Ihnen angezeigt wird)<br />
                        <a href="https://policies.google.com/technologies/ads?hl=de">https://policies.google.com/technologies/ads?hl=de</a> (Verwendung von Cookies in Anzeigen)<br />
                        <br />
                        <b><br />
                            Webserver Protokolldaten - Nicht personenbezogene Daten, die automatisch erfasst werden</b><br />
                        <br />
                        Bei der Nutzung unserer Internetseiten werden aus organisatorischen und technischen Gründen folgende Daten gespeichert: aufgerufene Seiten, verwendeter Browser / Betriebssystem sowie Gerätemodell, Datum und Uhrzeit des Zugriffs, verwendete Suchmaschine oder Links über den der Besucher auf die Website gelangt ist, anonymisierte IP-Adresse (nicht zurückverfolgbar), anonyme Sitzungskennung, eingestellte Sprache im Browser, Land aus dem der Zugriff erfolgt und eingegebene Suchbegriffe auf der Website, sofern verfügbar.<br />
                        Wir werten diese technischen Daten anonym, lediglich zu statistischen Zwecken und aus Sicherheitsgründen aus, um unseren Internetauftritt weiter optimieren und unsere Internetangebote noch attraktiver gestalten zu können. Diese anonymen Daten werden getrennt von personenbezogenen Informationen auf gesicherten Systemen gespeichert und lassen keine Rückschlüsse auf eine individuelle Person zu. Ihre personenbezogenen Daten und Ihre Privatsphäre sind jederzeit geschützt."<br />
                        <b><br />
                            <br />
                            Dateneinsicht von Dritten<br />
                        </b><br />
                        Unsere Website wird betreut und analysiert von ToNetwork UG (Im Moos 5 87749 Hawangen , tonetwork.de), welche Einsicht in die erhobenen Daten hat. Der Schutz Ihrer Daten wird durch einen bestehenden Auftragsverarbeitungsvertrag geregelt.<br />
                        <br />
                        <br />
                        <b>Rechte des Nutzers</b><br />
                        <br />
                        Sie haben als Nutzer das Recht, auf Antrag eine kostenlose Auskunft darüber zu erhalten, welche personenbezogenen Daten über Sie gespeichert wurden. Sie haben außerdem das Recht auf Berichtigung falscher Daten und auf die Verarbeitungseinschränkung oder Löschung Ihrer personenbezogenen Daten. Falls zutreffend, können Sie auch Ihr Recht auf Datenportabilität geltend machen. Sollten Sie annehmen, dass Ihre Daten unrechtmäßig verarbeitet wurden, können Sie eine Beschwerde bei der zuständigen Aufsichtsbehörde einreichen.<br />
                        <br />
                        <br />
                        <b>Löschung von Daten</b><br />
                        <br />
                        Sofern Ihr Wunsch nicht mit einer gesetzlichen Pflicht zur Aufbewahrung von Daten (z. B. Vorratsdatenspeicherung) kollidiert, haben Sie ein Anrecht auf Löschung Ihrer Daten. Von uns gespeicherte Daten werden, sollten sie für ihre Zweckbestimmung nicht mehr vonnöten sein und es keine gesetzlichen Aufbewahrungsfristen geben, gelöscht. Falls eine Löschung nicht durchgeführt werden kann, da die Daten für zulässige gesetzliche Zwecke erforderlich sind, erfolgt eine Einschränkung der Datenverarbeitung. In diesem Fall werden die Daten gesperrt und nicht für andere Zwecke verarbeitet.<br />
                        <br />
                        <br />
                        <b>Widerspruchsrecht</b><br />
                        <br />
                        Nutzer dieser Webseite können von ihrem Widerspruchsrecht Gebrauch machen und der Verarbeitung ihrer personenbezogenen Daten zu jeder Zeit widersprechen.<br />
                        <br />
                        Wenn Sie eine Berichtigung, Sperrung, Löschung oder Auskunft über die zu Ihrer Person gespeicherten personenbezogenen Daten wünschen oder Fragen bzgl. der Erhebung, Verarbeitung oder Verwendung Ihrer personenbezogenen Daten haben oder erteilte Einwilligungen widerrufen möchten, wenden Sie sich bitte an folgende E-Mail-Adresse: <a href="mailto:datenschutz@liepert.de">datenschutz@liepert.de</a><br />
                        <br />
                        <b><br />
                            Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</b><br />
                        <br />
                        Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz unseres Unternehmens befindet, also das Bayerische Landesamt für Datenschutzaufsicht, Ansbach (<a href="http://www.baylda.de">www.baylda.de</a>)<br />
                        <br />
                        <br />

                    </p>
                </div>
            </section>

            <MainFooter />
        </div>
    )
}