
import { React } from 'react'

import '../../main.min.css'
import './PartnerCard.min.css'
import './MPartnerCard.min.css'
import { lP, oP } from '../../main.js'


export default function PartnerCard(props) {
    return (
        <div className={'partnercard noselect ' + props.className} onClick={() => props.onclick()}>
            <div className='partnercardimgcont'><img className='partnercardimg' src={props.logoimg} alt="Logo des Partners" /></div>
            <div className='partnercardtextcont'>
                <b>{props.title}</b>
                <p>{props.description}</p>
            </div>
        </div>
    )
}