
import { React } from 'react'

import '../../main.min.css'
import './ServiceCard.min.css'
import './MServiceCard.min.css'
import { lP, oP } from '../../main.js'


export default function ServiceCard(props) {
    return (
        <div className={'servicecard noselect ' + props.className} onClick={() => props.onclick()}>
            <img src={props.iconimg} alt={"Service-Icon"} />
            <font>{props.title}</font>
        </div>
    )
}