import { React } from 'react'
import { Helmet } from 'react-helmet'
import { HashLink as Link } from 'react-router-hash-link';

import MainHeader from '../../components/MainHeader/MainHeader.js'
import MainFooter from '../../components/MainFooter/MainFooter.js'
import CTAButton from '../../components/CTAButton/CTAButton.js'

import { lP, oP, sT } from '../../main.js'

import '../../main.min.css'
import './Services.min.css'
import './MServices.min.css'


import service_icon_konversation from '../../assets/pictures/icons/konversation.png'
import service_icon_technischersupport from '../../assets/pictures/icons/technischer-support.png'
import service_icon_lokalesnetzwerk from '../../assets/pictures/icons/lokales-netzwerk.png'
import service_icon_netzwerkserver from '../../assets/pictures/icons/netzwerkserver.png'
import service_icon_computer from '../../assets/pictures/icons/computer.png'
import service_icon_telefon from '../../assets/pictures/icons/telefon.png'
import service_icon_chatbox from '../../assets/pictures/icons/chat-box.png'
import service_icon_privacypolicy from '../../assets/pictures/icons/privacy-policy.png'

import services_start_img from '../../assets/pictures/backgrounds/alexandre-debieve-FO7JIlwjOtU-unsplash.jpg'
import ServicesListCard from '../../components/ServicesListCard/ServicesListCard.js'

export default function Services() {


    return (
        <div className='mainCon' id='mainCon'>
            <MainHeader selectedsite={"services"} />
            <Helmet>
                <meta charSet="utf-8" />
                <title>Leistungen</title>
                <meta property="og:title" content="Leistungen" />
                <meta property="og:description" content="Wir bieten Ihnen einen umfangreichen IT-Service von Netzwerkaufbau, Server, Telefonanlagen bis IT-Support. Erfahren Sie jetzt mehr über unsere Leistungen!" />
            </Helmet>
            <section className='sec_start' id='sec_start--services'>
                <img src={services_start_img} className='sec_start_bgimg' id='services_start_img' alt="Hintergrundbild der Seite auf denen die Dienstleistungen aufgelistet sind mit einem verschwommenen Mainboard eines Computers im Hintergrund" />
                <h1 className="sec_start_title noselect">Diese Services bieten<br />wir Ihnen:</h1>
                <Link smooth to='#sec_next--services--services' >
                    <CTAButton className="sec_start_btn" hideArrow={true} title={"Erfahren Sie mehr"} darkcta={false} onclick={() => { }}></CTAButton>
                </Link>
                {/* <CTAButton className="sec_start_btn" hideArrow={true} title={"Erfahren Sie mehr"} darkcta={false} onclick={() => sT('#sec_next--services--services')}></CTAButton> */}
            </section>

            <section className='sec_next' id='sec_next--services--services'>
                <div className='sec_next_cont' id="sec_next--services--services--cont">

                    {/* <h2>Services</h2> */}
                    <br /><br />
                    <div className='serviceslistcont'>
                        <ServicesListCard chorder={false} title="IT-Beratung" className="" iconimg={service_icon_konversation} description="Wir sind Ihr Partner bei allen Themen rund um die EDV und Kommunikation im Unternehmen. Wir planen mit Ihnen zusammen die notwendige IT-Ausstattung und beraten Sie bei allen zugehörigen Themen. Unser Ziel ist es, eine integrierte Lösung für die gesamte Informationsverarbeitung in Ihrem Unternehmen zu entwickeln." />
                        <ServicesListCard chorder={true} title="IT-Support" className="" iconimg={service_icon_technischersupport} description="Unser Service umfasst eine umfassende Betreuung Ihrer EDV und Sicherheitssysteme, individuell auf Ihre Bedürfnisse zugeschnitten. Neben Wartung und Support bieten wir Ihnen kompetente Beratung zur Optimierung und Erweiterung Ihrer Infrastruktur." />
                        <ServicesListCard chorder={false} title="Netzwerktechnik" className="" iconimg={service_icon_lokalesnetzwerk} description="Eine moderne und strukturierte Verkabelung bildet die Grundlage für ein flexibles und erweiterbares Netzwerk. Wir planen und realisieren Netzwerke mit einer Kapazität von 5 bis 500 Anschlüssen, vom Schrank bis zur Datendose. Wir überwachen sorgfältig die Umsetzung der Verkabelung und koordinieren die Kommunikation mit den ausführenden Firmen, um sicherzustellen, dass Ihre Anforderungen optimal umgesetzt werden." />
                        <ServicesListCard chorder={true} title="Serverinfrastruktur" className="" iconimg={service_icon_netzwerkserver} description="Als langjähriger und zertifizierter Partner von Hewlett-Packard, einem Technologieführer im Bereich Server und Speichersysteme, bieten wir Ihnen umfassende Lösungen. Die Serversysteme der „ProLiant“-Reihe sind äußerst vielseitig und können nahezu jede Aufgabe und Anforderung abdecken. Ob Sie Einzelserver benötigen oder komplexe Cluster mit Speicher-Subsystemen planen, wir verfügen über das erforderliche Know-how und die Erfahrung, um Ihre Anforderungen zu erfüllen." />
                        {/* <ServicesListCard chorder={false} title="Hardwareservice" className="" iconimg={service_icon_computer} description="Vor-Ort-Service aller Geräte und Anlagen. Aktualisierung und Integration von Softwareprodukten in Ihr bestehendes System." /> */}
                        <ServicesListCard chorder={false} title="MS365 und Azure" className="" iconimg={service_icon_chatbox} description="Cloud-Lösungen haben an Funktion und Bedeutung gewonnen – sie sind jedoch nicht immer die optimale Lösung für jeden Anwendungsfall. Gerade im Bereich Mail-Konferenz-Kommunikation lassen sich jedoch sehr effektive Lösungen schaffen. Über die TELEKOM bieten wir auch die vollständige Integration ihrer Festnetz- oder Mobilnummer an. In vielen Projekten haben wir ausgewogene, hybride Lösungen erstellt." />
                        <ServicesListCard chorder={true} title="Telekommunikation" className="" iconimg={service_icon_telefon} description="Ob hybride Systeme des deutschen Herstellers AGFEO oder virtuelle Telefonie per Teams – ohne Telefonie geht es auch heutzutage nicht! Als zertifizierter AGFEO Gold-Partner planen wir Ihre individuell zugeschnittene TK-Anlage vom Einzelbüro bis zur Firmenlösung mit bis zu 200 Endgeräten. In mehreren Projekten haben wir die Integration in Microsoft-Teams erfolgreich realisiert." />
                        {/* <ServicesListCard chorder={true} title="Unified Messaging" className="" iconimg={service_icon_chatbox} description="Unified Messaging vereint E-Mails, Voicemails und sonstige Nachrichten in einem Postfach, sodass Sie überall und auf jedem Gerät auf all Ihre Kommunikation zugreifen können. Es ist wie ein persönlicher Kommunikationsassistent, der dafür sorgt, dass Sie nie wieder eine wichtige Nachricht verpassen. Einfach, effizient und zeitsparend – so bleibt Ihre Kommunikation immer auf dem neuesten Stand!" /> */}
                        <ServicesListCard chorder={false} title="Datenschutz" className="" iconimg={service_icon_privacypolicy} description="Wussten Sie: Praktisch jeder Betrieb mit mehr als 20 Mitarbeitern braucht einen Datenschutzbeauftragten. Jeder Betrieb muss ein öffentliches Verfahrensverzeichnis erstellen. Sie müssen Ihre Mitarbeiter auf Wahrung des Datengeheimnisses schriftlich verpflichten. Verstöße können stets mit Bußgeld geahndet werden. usw. Zwei unserer Mitarbeiter haben sich entsprechend fortgebildet und wurden vom TÜV zum DSB zertifiziert." />
                    </div>

                    <CTAButton className="cta_bottom" hideArrow={false} title={"Jetzt Kontakt aufnehmen"} darkcta={true} onclick={() => lP('/kontakt')}></CTAButton>

                </div>
            </section >

            <MainFooter />
        </div >
    )
}