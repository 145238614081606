
import { React, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons'

// import logo_breit from '../../assets/pictures/logos/it-liepert-logo-obg.png'
import logo_breit from '../../assets/pictures/logos/it-liepert-logo_textunten_obgs.png'

import { lP, oP, sT, APPMODE } from '../../main.js'

import './MainHeader.min.css'
import './MMainHeader.min.css'
import '../../main.min.css'

import Cookies from 'universal-cookie'

const cookies = new Cookies()


export default function MainHeader(props) {

    var [menuopenState, setMenuOpenState] = useState(false)

    useEffect(() => {
    }, [])
    var hideMenu = props.hideMenu

    useEffect(() => {
        try {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && window.screen.orientation.type != "portrait-primary") {
                document.querySelector(".orientationModal").style.display = "block"
            } else {
                document.querySelector(".orientationModal").style.display = "none"
            }
        } catch (e) {
            console.log(e)
        }


        var menumodal = document.querySelector(".mainmenu")
        var menumodalbg = document.querySelector(".mainmenumodalbg")
        const clicklistener = event => {
            if (event.target != menumodal && event.target == menumodalbg) {
                console.log("close")
                setMenuOpenState(false)
                // event.preventDefault()
            }
        }
        const orientationlistener = event => {
            console.log("rotate")
            try {
                if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && window.screen.orientation.type != "portrait-primary") {
                    document.querySelector(".orientationModal").style.display = "block"
                } else {
                    document.querySelector(".orientationModal").style.display = "none"
                }
            } catch (e) {
                console.log(e)
            }

        }
        document.addEventListener("click", clicklistener)
        window.addEventListener('orientationchange', orientationlistener)

        return () => {
            document.removeEventListener("click", clicklistener)
            document.removeEventListener("orientationchange", orientationlistener)
        }

    })

    let selsite = props.selectedsite
    function setSelCN(lsite) {
        return (lsite == selsite) ? "selsite" : "";
    }

    return (
        <div>
            {(APPMODE != "prod") ?
                <div className="DEVinfo">
                    🛠️ ACHTUNG: ENTWICKLUNGSVERSION 🛠️
                </div> : <div></div>}
            <div className="WARNinfo" style={{ display: 'none' }}>
                ❕ Weltweite Microsoft-Störung behoben: <a href='/aktuelles/005-microsoft-stoerung'>Mehr erfahren</a> ❕
            </div>
            <div className="orientationModal">
                Bitte nutze unsere Webseite nur im Hochformat.
            </div>
            <div className='mainheaderdiv noselect' style={{ marginTop: (APPMODE != "prod") ? "20px" : "0" }}>
                <div className='mainheaderfirst'>
                    <a href="/"><img className='mainlogo' src={logo_breit} style={{ cursor: 'pointer' }} alt="IT-Liepert Logo" /></a>
                    <div className='mainheadermobilebtn'>
                        {(hideMenu) ? <div></div> : (menuopenState) ? <FontAwesomeIcon className="headerbtnicon menuicon" icon={faXmark} onClick={() => { setMenuOpenState(!menuopenState) }} /> : <FontAwesomeIcon className="headerbtnicon menuicon" icon={faBars} onClick={() => { setMenuOpenState(!menuopenState) }} />}
                    </div>
                </div>

                <div className={(menuopenState) ? "mainheadersecond menuopenedsecond" : "mainheadersecond"}>
                    <div className={(menuopenState) ? "mainheaderbtnlist menuopenedbtnlist" : "mainheaderbtnlist"}>
                        <a href='/services' className={setSelCN("services")}>Services</a>
                        <a href='/about' className={setSelCN("about")}>Über uns</a>
                        <a href='/aktuelles' className={setSelCN("aktuelles")}>Aktuelles</a>
                        <a href='/referenzen' className={setSelCN("referenzen")}>Referenzen & Partner</a>
                        <a href='/kontakt' className={setSelCN("kontakt")}>Kontakt</a>
                    </div>

                </div>
            </div>
        </div>

    )
}