import { React } from 'react'
import { useParams } from 'react-router-dom'

import MainHeader from '../../components/MainHeader/MainHeader.js'
import MainFooter from '../../components/MainFooter/MainFooter.js'
import CTAButton from '../../components/CTAButton/CTAButton.js'

import newsdatajson from '../../assets/newsdata.json'

import { lP, oP, sT } from '../../main.js'

import '../../main.min.css'
import './AktuellesDetail.min.css'
import './MAktuellesDetail.min.css'

import icon_news from '../../assets/pictures/icons/news.png'
import icon_personadd from '../../assets/pictures/icons/personadd.png'

export default function AktuellesDetail() {

    let { newsidurl } = useParams()
    let newsdata = newsdatajson.find((element) => element.newsid == newsidurl);

    let newstitle = newsdata.title != "" ? newsdata.title : "Fehler beim Laden des Titels"
    let newsdatum = newsdata.datum != "" ? newsdata.datum : "00.00.0000"
    let newsbody = newsdata.body != "" ? newsdata.body : "Fehler beim Laden des Newstextes."

    // const parser = new DOMParser();
    // const htmlnewsbody = parser.parseFromString(newsbody, 'text/xml');

    return (
        <div className='mainCon' id='mainCon'>
            <MainHeader selectedsite={"aktuelles"} />

            <section className='sec_next' id='sec_next--aktuellesdetail'>
                <div className='sec_next_cont' id="sec_next--aktuellesdetail--cont">
                    <a className="pagebackbtn" href="/aktuelles">◀️ Zurück zur Übersicht</a>
                    <h2>{newstitle}</h2>
                    <b>{newsdatum}</b>
                    <div className='newsbodydiv' dangerouslySetInnerHTML={{ __html: newsbody }}></div>

                </div>
            </section>

            <MainFooter />
        </div>
    )
}