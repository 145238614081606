import { React, useState, useEffect } from 'react'
import { HashLink as Link } from 'react-router-hash-link';

import MainHeader from '../../components/MainHeader/MainHeader'
import MainFooter from '../../components/MainFooter/MainFooter'
import CTAButton from '../../components/CTAButton/CTAButton'
import ServiceCard from '../../components/ServiceCard/ServiceCard'
import NewsCard from '../../components/NewsCard/NewsCard'

import newsdatajson from '../../assets/newsdata.json'

import { lP, oP, sT } from '../../main.js'

import '../../main.min.css'
import './Home.min.css'
import './MHome.min.css'

import home_start_img from '../../assets/pictures/backgrounds/christopher-gower-m_HRfLhgABo-unsplash.jpg'
import home_about_img from '../../assets/pictures/backgrounds/marten-bjork-6dW3xyQvcYE-unsplash.jpg'

import service_icon_konversation from '../../assets/pictures/icons/konversation.png'
import service_icon_technischersupport from '../../assets/pictures/icons/technischer-support.png'
import service_icon_lokalesnetzwerk from '../../assets/pictures/icons/lokales-netzwerk.png'
import service_icon_netzwerkserver from '../../assets/pictures/icons/netzwerkserver.png'
import service_icon_computer from '../../assets/pictures/icons/computer.png'
import service_icon_telefon from '../../assets/pictures/icons/telefon.png'
import service_icon_chatbox from '../../assets/pictures/icons/chat-box.png'
import service_icon_privacypolicy from '../../assets/pictures/icons/privacy-policy.png'
// import icon_gruppe from '../../assets/pictures/icons/gruppe.png'
import icon_news from '../../assets/pictures/icons/news.png'
import icon_personadd from '../../assets/pictures/icons/personadd.png'

// import teambild_klaus_liepert from '../../assets/pictures/teambilder/liepert_klaus_detail-2.jpg'
// import teambild_ralf_konefal from '../../assets/pictures/teambilder/konefal_ralf_detail-2.jpg'

import teambild_klaus_liepert from '../../assets/pictures/teambilder/liepert_klaus_detail-3.png'
import teambild_ralf_konefal from '../../assets/pictures/teambilder/konefal_ralf_detail-3.png'

import liepert_map from '../../assets/pictures/liepert-map.png'


export default function Home() {

    let newsdatalist = newsdatajson.slice(0, 3)

    return (
        <div className='mainCon' id='mainCon'>
            <MainHeader selectedsite={""} />

            <section className='sec_start' id='sec_start--home'>
                <img src={home_start_img} className='sec_start_bgimg' id='home_start_img' alt="Hintergrundbild der Startseite mit einem verschwommenen Laptop im Hintergrund" />
                <h1 className="sec_start_title noselect">Ihr zuverlässiger Partner<br />für Ihre Firmen-IT</h1>
                {/* <CTAButton className="sec_start_btn" hideArrow={true} title={"Jetzt Gespräch vereinbaren"} darkcta={false} onclick={() => lP('/kontakt')}></CTAButton> */}
                <Link smooth to='#sec_next--home--services' >
                    <CTAButton className="sec_start_btn" hideArrow={true} title={"Lernen Sie uns kennen"} darkcta={false} onclick={() => { }}></CTAButton>
                </Link>
            </section>
            <section className='sec_next' id='sec_next--home--services'>
                <div className='servicesgridcont'>
                    <div className='servicestitlecont'>
                        Unsere<br />Dienstleistungen
                    </div>
                    <ServiceCard title="IT-Beratung" iconimg={service_icon_konversation} onclick={() => lP('/services')} />
                    <ServiceCard title="IT-Support" iconimg={service_icon_technischersupport} onclick={() => lP('/services')} />
                    <ServiceCard title="Netzwerk" iconimg={service_icon_lokalesnetzwerk} onclick={() => lP('/services')} />
                    <ServiceCard title="Server" iconimg={service_icon_netzwerkserver} onclick={() => lP('/services')} />
                    <ServiceCard title="Hardware" iconimg={service_icon_computer} onclick={() => lP('/services')} />
                    <ServiceCard title="MS365 & Azure" iconimg={service_icon_chatbox} onclick={() => lP('/services')} />
                    <ServiceCard title="Telefon" iconimg={service_icon_telefon} onclick={() => lP('/services')} />
                    {/* <ServiceCard title="Unified Messaging" iconimg={service_icon_chatbox} onclick={() => lP('/services')} /> */}
                    <ServiceCard title="Datenschutz" iconimg={service_icon_privacypolicy} onclick={() => lP('/services')} />
                    {/* <div style={{ width: "22vw" }}></div> */}

                </div>
            </section>
            <section className='sec_nextmbg' id='sec_next--home--about'>
                <img src={home_about_img} className='sec_nextmbg_bgimg' id='home_about_img' alt="Hintergrundbild der Startseite mit einer verschwommenen Person mit Aktenkoffer" />
                <div className='sec_nextmbg_onimgcont' id="sec_next--home--about--cont">
                    <h2>Wer sind wir?</h2>
                    <div className="shortabouttextcont">
                        <p>
                            Die it.liepert.gmbh ist ein regional tätiges Systemhaus, das seit 1993 kleine und mittelständische Unternehmen im Großraum Memmingen in allen Fragen rund um die IT und Kommunikation berät und betreut. Dabei legen wir besonderen Wert auf integrierte Lösungen, die die informationstechnischen Prozesse des Unternehmens in seiner Gesamtheit bestmöglich erfasst und abbildet. Viele namhafte Unternehmen der Region zählen seit vielen Jahren zu unserem Kundenstamm.
                        </p>
                        {/* <img src={icon_gruppe} alt={"Icon Gruppe an Personen"} /> */}
                    </div>
                    <CTAButton className="cta_more" hideArrow={false} title={"Erfahren Sie mehr über uns"} darkcta={true} onclick={() => lP('/about')}></CTAButton>

                    <div className='shortaboutteamcont'>
                        <div className='shortaboutteamcard'>
                            <img src={teambild_klaus_liepert} alt={"Bild von Klaus Liepert (Geschäftsführer)"} />
                            <div className='shortaboutteamcardtextcont'>
                                <b>Klaus Liepert</b>
                                <p>Dipl. Informatiker (FH), Geschäftsführer</p>
                                <a href="/about#klausliepert">Kontakt & Weitere Infos</a>
                                {/* TODO: IDs auf About-Seite festlegen damit Verlinkung funktioniert */}
                            </div>
                        </div>
                        <div className='shortaboutteamcard'>
                            <img src={teambild_ralf_konefal} alt={"Bild von Ralf Konefal (Geschäftsführer)"} />
                            <div className='shortaboutteamcardtextcont'>
                                <b>Ralf Konefal</b>
                                <p>Geschäftsführer</p>
                                <a href="/about#ralfkonefal">Kontakt & Weitere Infos</a>
                                {/* TODO: IDs auf About-Seite festlegen damit Verlinkung funktioniert */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='sec_next' id='sec_next--home--news'>
                <div className='sec_next_cont' id="sec_next--home--news--cont">

                    <h2>Aktuelles bei uns</h2>

                    <div className='newscont'>
                        {/* <div className='newscont_nonewstext'>Momentan sind keine Neuigkeiten vorhanden.<br /><br /><br /><br /><br /><br /><br /></div> */}
                        {newsdatalist.map(function (newsdata) {
                            return (
                                <NewsCard newsid={newsdata.newsid} title={newsdata.title} datum={newsdata.datum} iconimg={(newsdata.icontype == "personadd") ? icon_personadd : icon_news} ausschnitt={newsdata.ausschnitt} />
                            )
                        })}
                    </div>
                    <br /><br /><br />
                    <CTAButton className="cta_bottom" hideArrow={true} title={"Alle News anzeigen"} darkcta={true} onclick={() => lP('/aktuelles')}></CTAButton>

                </div>
            </section>

            <section className='sec_next' id='sec_next--home--kontakt'>
                <div className='sec_next_cont' id="sec_next--home--kontakt--cont">

                    <h2>Kontakt</h2>

                    <div className='contactcont'>
                        <div className='contacttextcont'>
                            <b>it.liepert.gmbh</b>
                            <p>
                                it-systemhaus<br />
                                ingenieurbüro
                            </p>
                            <a href="https://www.google.com/maps/search/?api=1&query=itliepert+Zeissweg+4+87700+Memmingen&hl=de" className="addresslink">Zeissweg 4<br />87700 Memmingen</a>
                            <br />
                            <a href="tel:0833196700">fon +49.8331.9670.0</a>
                            <a href="fax:08331967040">fax +49.8331.9670.40</a>
                            <a href="mailto:info@liepert.de">eMail info@liepert.de</a>
                        </div>
                        <img src={liepert_map} alt={"Karte der Umgebung für die Anfahrt"} />

                    </div>
                    <br /><br /><br />
                    <CTAButton className="cta_more" hideArrow={false} title={"Kontaktieren Sie uns"} darkcta={true} onclick={() => lP('/kontakt')}></CTAButton>

                </div>
            </section>

            <MainFooter />
        </div>
    )
}