
import { React } from 'react'

import '../../main.min.css'
import './ServicesListCard.min.css'
import './MServicesListCard.min.css'
import { lP, oP } from '../../main.js'


export default function ServicesListCard(props) {

    let reversecss = (props.chorder != true) ? '' : 'reverseserviceslistcard'

    return (
        <div className={'serviceslistcard ' + props.className + ' ' + reversecss} >
            {/* onClick={() => props.onclick()} */}
            {/* noselect */}
            {/* <div className='serviceslistcard'> */}
            <img className='serviceslistcardimg' src={props.iconimg} />
            <div className='serviceslistcardtextcont'>
                <b>{props.title}</b>
                <p>{props.description}</p>
            </div>
        </div>
        // </div >
    )
}